import horizontalHero from '@elegantstack/flow-ui-theme/src/theme/cards/horizontal-hero'

export default {
  ...horizontalHero,
  media: {
    ...horizontalHero.media,
    mt: 2,
  },
  body: {
    ...horizontalHero.body,
    bg: `transparent`,
    p: [3, 0]
  },
  excerpt: {
    ...horizontalHero.excerpt,
    display: `block`,
    mt: [1, 4],
  }
}