import React from 'react'
import Helmet from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import { getSrc } from 'gatsby-plugin-image'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'
import getImageVariant from '@components/utils/getImageVariant'

const Seo = ({
  description,
  slug,
  excerpt,
  meta,
  keywords,
  author,
  category,
  date,
  timeToRead,
  children,
  thumbnail,
  siteUrl
}) => {
  const site = useSiteMetadata()
  const title = 'Tạp chí Wattdaily'
  const social = (author && author.social) || site.social || []
  const twitter =
    social.find(s => s.name && s.name.toLowerCase() === 'twitter') || {}

  description = excerpt || description || site.description

  const imageSrc = getSrc(getImageVariant(thumbnail, 'hero'))
  const imageUrl =
    imageSrc &&
    (imageSrc.startsWith('//') ? imageSrc : siteUrl && `${siteUrl}${imageSrc}`)

  /**
   * Meta Tags
   */

   const metaTags = [
    { itemprop: 'name', content: title || site.title },
    { itemprop: 'description', content: description },
    { name: 'description', content: description },

    { property: 'og:title', content: title || site.title },
    { property: 'og:description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.name },
    { property: 'og:image', content: imageUrl },

    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: site.name },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: twitter.url }
  ]

  if (keywords && keywords.length > 0) {
    metaTags.push({ name: 'keywords', content: keywords.join(', ') })
  }

  if (date) {
    metaTags.push({ name: 'article:published_time', content: date })
  }

  if (timeToRead) {
    metaTags.push({ name: 'twitter:label1', value: 'Reading time' })
    metaTags.push({
      name: 'twitter:data1',
      value: `${timeToRead} min read`
    })
  }

  if (meta) {
    metaTags.push(meta)
  }

  /**
   * Structured Data (JSON-LD)
   */

  const scripts = []

  // Article
  if (title && author) {
    const articleJsonLd = helmetJsonLdProp({
      '@context': 'https://schema.org',
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `${siteUrl}${slug}`
      },
      headline: title,
      image: imageUrl,
      datePublished: date,
      dateModified: date,
      author: {
        "@type": 'Person',
        name: author.name
      },
      publisher: {
        '@type': 'Organization',
        name: site.name,
        logo: {
          '@type': 'ImageObject',
          url: 'https://wattdaily.com/static/e429020c8a2050a374cdfd338c00f16f/d7151/logo.webp',
          width: 150,
          height: 35
        }
      }
    })
    scripts.push(articleJsonLd)
  }

  // NewsArticle
  if (title && author) {
    const newsArticleJsonLd = helmetJsonLdProp({
      '@context': 'https://schema.org',
      '@type': "NewsArticle",
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `${siteUrl}${slug}`
      },
      headline: title || site.title,
      description: description,
      image: {
        '@type': 'ImageObject',
        url: imageUrl,
        width: 900,
        height: 540
      },
      datePublished: date,
      dateModified: date,
      author: {
        "@type": 'Person',
        name: author.name
      },
      publisher: {
        '@type': 'Organization',
        name: site.name,
        logo: {
          '@type': 'ImageObject',
          url: 'https://wattdaily.com/static/e429020c8a2050a374cdfd338c00f16f/d7151/logo.webp',
          width: 150,
          height: 35
        }
      },
      about: [keywords && keywords.length > 0 ? keywords.join(', ') : '']
    })
    scripts.push(newsArticleJsonLd)
  }

  // Breadcrumb
  if (title && category) {
    const breadcrumbJsonLd = helmetJsonLdProp({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: site.name
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': `${siteUrl}${category.slug}`,
            name: category.name
          }
        }
      ]
    })
    scripts.push(breadcrumbJsonLd)
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'vi'
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={metaTags}
      script={scripts}
    >
      {children}
    </Helmet>
  )
}

export default Seo
