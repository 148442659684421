export default {
  layout: {
    flexDirection: 'column',
    height: `100%`,
  },
  header: {
    justifyContent: `space-between`,
    alignItems: `center`,
    height: [`4rem`, `5rem`], //prevent layout shift
    pt: [3, 4],
    pb: [2, 3]
  },
  // bgbody:{
  //   height: `100vh`,
  //   backgroundImage: `url(../../../../../../../site/content/assets/TheLandmark_Swanlake-BackgroundBanner_DanTri_.jpeg)`,
  //   backgroundPosition: 'center',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover',
  //   position: '-webkit-sticky',
  //   position: 'sticky',
  //   top: '0px',
  //   zIndex: -1,
  //   display: ['none', 'block']
  // },
  body: {
    flex: `1 1 auto`,
    backgroundColor: t => t.colors.background,
    // backgroundColor: 'transparent',
    mx: [0, 0, 0, `2vw`, `calc((100vw - 1080px) / 2)`],
    mt: [null, 220],
    borderRadius: 20,
    zIndex: 1
  },
  footer: {
    flexDirection: [`column-reverse`, `row`],
    justifyContent: `space-between`,
    alignItems: [`center`, `flex-start`],
    py: 5
  },

  /** Container */
  container: {
    maxWidth: `container`,
    px: [3, 4]
  },
  /** Container (full width version) */
  wide: {
    maxWidth: `wide`,
    px: [3, 4]
  },
  full: {
    maxWidth: `none`
  },
  /** Container (compact version) */
  compact: {
    px: [3, 4]
  },

  /** Main Container with sidebar support */
  main: {
    flex: 1,
    ml: 0
  },
  /** Sidebar */
  sidebar: {
    display: [`none`, null, `block`],
    flexBasis: `1/4`,
    pl: 4,
    mr: 0
  },

  /** Sections in container */
  section: {
    maxWidth: `none`
  },

  /** Sidebar Container */
  'section-aside': {
    variant: `layout.section`
  },

  /** Sticky Box */
  sticky: {
    position: `sticky`,
    top: 4
  }
}
