import theme from '@elegantstack/flow-ui-theme/src/theme'
import { Global } from '@emotion/core'
import pageContextProvider from '@helpers/pageContextProvider'
import React from 'react'
import { Box, css, Flex, Link, ThemeProvider } from 'theme-ui'
import Scroll from '../../flow-ui-components/Scroll/Scroll'
import Banner from '../../flow-ui-widgets/Banner/Banner'
import BannerFull from '../../flow-ui-widgets/BannerFull/BannerFull'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { Hero } from '@layout'
import BannerHorizontal from '@widgets/BannerHorizontal'




export const Layout = ({ children, pageContext, location }) => (
  <ThemeProvider theme={theme}>
    <pageContextProvider.Provider value={{ pageContext, location }}>
      <Flex variant='layout.layout'>
        <Global styles={css(theme => theme.global)} />
        <Header />
        <BannerFull />
        <Box variant='layout.body'>{children}</Box>
        <Scroll showBelow={1000} />
        <Footer />
      </Flex>
    </pageContextProvider.Provider>
  </ThemeProvider>
)
