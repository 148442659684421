export default {
  bg: `contentBg`,
  borderRadius: `lg`,
  transition: `
    transform 250ms ease,
    box-shadow 250ms ease,
    color 250ms ease
  `,
  border: '1px solid',
  borderColor: 'omegaLighter'
}
