import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Box, Link } from 'theme-ui'

const styles = {
  wrapper: src => ({
    height: `1200px`,
    width: `100vw`,
    backgroundImage: src && [`none`, `url(${src})`],
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: '-webkit-sticky',
    position: 'sticky',
    position: "absolute",
    zIndex: -1,
    display: ['none', 'block'],
    top: 0,
    maxWidth: 1920,
    maxHeight: 1200,
    left: [null, null, null, null, null, "calc((100vw - 1920px) / 2)"],
  }),
  adsLink: {
    display: ['none', 'block'],
    position: '-webkit-sticky',
    position: 'sticky',
    top: '0px',
    zIndex: 0,
  }
}
const nameList = ['bannerfull.jpeg', 'bannerfull-2.png', 'bannerfull-3.png', 'bannerfull-4.png']
// const titleList = ['wattdaily', 'chamhoi', 'formtory', 'onesocial']
const linkList = ['https://ecopark.com.vn/the-landmark-dop-77.html', 'https://marinatown.vn/', 'https://hyundai.tcmotor.vn/i10hatchback', 'https://hyundai.tcmotor.vn/san-pham/santafe-2021']

const BannerFull = React.memo(() => {
  // let numberList = localStorage.numberList ? JSON.parse(localStorage.numberList) : [0, 1, 2, 3, 4, 5]

  const data = useStaticQuery(BannerFullQuery)
  const { allImagesWithoutSVGExtension, allImagesWithSVGExtension } = data

  // var map = numberList.reduce(function(p, c) {
  //   p[c] = (p[c] || 0) + 1;
  //   return p;
  // }, {});

  // var newTypesArray = Object.keys(map).sort(function(a, b) {
  //   return map[a] - map[b];
  // });
  // var numberId;
  // do {
  //   numberId = Math.trunc(Math.random() * nameList.length)
  // }
  // while (numberId !== JSON.parse(newTypesArray[0]));
  // numberList.push(numberId)

  // localStorage.setItem('numberList', JSON.stringify(numberList));
  // const  name = nameList[newTypesArray[0] || numberId];
  const nameId = Math.trunc(Math.random() * nameList.length)
  const name = nameList[nameId]
  const isNameWithSVGExtension = name.indexOf('gif') !== -1;

  const renderImageWithoutSVGExtension = () => {
    const image = allImagesWithoutSVGExtension.nodes.find(
      ({ publicURL }) => publicURL && publicURL.indexOf(name) !== -1
    );
    return image && image.sharp && image.sharp.fluid ? (
      <Box sx={styles.wrapper(image.sharp.fluid.srcWebp)}></Box>
    ) : null;
  };


  const renderImageWithSVGExtension = () => {
    const image = allImagesWithSVGExtension.nodes.find(
      ({ publicURL }) => publicURL && publicURL.indexOf(name) !== -1
    );
    return image ? (
      <Box sx={styles.wrapper(image.publicURL)}></Box>
    ) : null;
  };
  return (
    <Link
      target='_blank'
      // title={titleList[nameId]}
      href={linkList[nameId]}
      rel='noopener noreferrer'
      sx={styles.adsLink}
    >
      {
        isNameWithSVGExtension
          ? renderImageWithSVGExtension()
          : renderImageWithoutSVGExtension()
      }
    </Link>
  )




})

const BannerFullQuery = graphql`
  query BannerFullQuery {
    allImagesWithoutSVGExtension: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        extension: { regex: "/jpeg|jpg|png/" }
      }
    ) {
      nodes {
        publicURL
        extension
        sharp: childImageSharp {
          fluid(maxWidth: 1920, quality:100) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    allImagesWithSVGExtension: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        extension: { regex: "/gif|svg/" }
      }
    ) {
      nodes {
        publicURL
        extension
      }
    }
  }
`

export default BannerFull
