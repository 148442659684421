import React, { useEffect, useState } from 'react';
import { FaChevronUp, FaChevronLeft } from 'react-icons/fa';
import smoothscroll from 'smoothscroll-polyfill';
import { Box, IconButton } from 'theme-ui';
import { navigate } from "gatsby"

// kick off the polyfill!
if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}


const styles = {
  wrapper: {
    zIndex: 4
  },
  toTop: {
    position: 'fixed',
    bottom: '2vh',
    right: ['3%', '4%'],
    backgroundColor: 'omegaLight',
    color: 'omegaDark',
    "&:hover": {
      transition: '0.3s',
      color: '#397BA6',
      backgroundColor: 'omegaDarker'
    },
  }
}

const Scroll = ({
  showBelow,
}) => {
  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return (
    <Box sx={styles.wrapper}>
      {/* <IconButton onClick={() => navigate(-1)} sx={styles.toTop}>
        <FaChevronLeft />
      </IconButton> */}
      {show &&
        <IconButton onClick={handleClick} sx={styles.toTop}>
          <FaChevronUp />
        </IconButton>
      }
    </Box>
  )
}
export default Scroll