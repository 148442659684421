export default {
  imageVariant: `hero`,
  content: {
    flexDirection: `column`
  },
  media: {
    width: [`full`, `auto`],
    maxWidth: [360, `none`],
    flexBasis: 0,
    m: 2,
    mb: 0,
    '&::before, &::after': {
      content: `""`,
      bg: `omegaLight`,
      position: `absolute`,
      borderRadius: t => `${t.radii.default} 0`
    },
    '::before': {
      top: -2,
      left: -2,
      size: `2/3`
    },
    '::after': {
      bottom: -2,
      right: -2,
      size: 80,
      borderRadius: [`0`, `3rem 0`]
    }
  },
  image: {
    // minHeight: `8.3rem`,
    // maxHeight: `20rem`,
    // img: {
    //   borderRadius: `default`
    // }

    minHeight: `8.3rem`,
    maxHeight: `25rem`,
    bg: `transparent`,
    zIndex: 2,
    img: {
      borderRadius: t => [
        `${t.radii.default} 3rem 0 0`,
        `${t.radii.default} 3rem 3rem`
      ],
      objectFit: `none !important`
    }
  },
  iconBox: {
    borderRadius: `default`,
    minHeight: `8.3rem`
  },
  iconText: {
    display: [`none`, `block`]
  },
  icon: {
    size: `icon.lg`
  },
  body: {
    p: 4,
    pb: 0,
  },
  category: {
    display: `inline-block`
  },
  title: {
    variant: `text.h1`,
    flex: [0, null, `auto`]
  },
  excerpt: {
    display: `block`,
    fontSize: 2,
  },
  footer: {
    variant: `text.small`
  },
  authorPhoto: {
    display: `block`,
  },
  author: {
    display: `inline-block`,
    flexBasis: `full`
  },
  date: {
    display: `inline-block`
  },
  timeToRead: {
    display: `inline-block`
  }
}
