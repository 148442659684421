import primary from './primary'
import paper from './paper'
import search from './search'
import verticalFeature from './vertical-feature'
import horizontalHero from './horizontal-hero'
import cards from '@elegantstack/flow-ui-theme/src/theme/cards/index'

export default {
  ...cards,
  primary,
  'horizontal-hero': horizontalHero,
  'vertical-feature': verticalFeature,
  paper,
  search
}
