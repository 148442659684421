import baseColors from '@elegantstack/flow-ui-theme/src/theme/colors'

//Using tailwind preset colors
import colorPreset from '@elegantstack/flow-ui-theme/src/theme/color-preset'

const colors = {
  ...baseColors,
  background: '#fff',
}

export default colors