import { useBlogCategories } from '@helpers-blog'
import pageContextProvider from '@helpers/pageContextProvider'
import Categories from '@widgets/Categories'
import Search from '@widgets/Search'
import React, { useContext } from 'react'
import { Box, Container, Flex } from 'theme-ui'
import { HeaderColorMode } from './Header.ColorMode'
import { HeaderLogo } from '@elegantstack/flow-ui-layout/src/Header/Header.Logo'
import Sticky from '@components/Sticky'
import Banner from '../../flow-ui-widgets/Banner/Banner'


const styles = {
  wrapper: {
    position: `relative`,
    bg: `headerBg`,
  },
  container: {
    position: `relative`,
    zIndex: 10
  },
  logoContainer: {
    flexBasis: [`full`, null, `2/4`],
    order: [2, null, `unset`]
  },
  searchContainer: {
    flexBasis: [`auto`, null, `1/4`],
    minWidth: `auto`,
    order: [1, null, `unset`],
    // mx: 3,
  },
  colorModeContainer: {
    flexBasis: [`auto`, null, `1/4`],
    minWidth: `auto`,
    order: [3, null, `unset`],
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  categoriesContainer: {
    display: [`none`, `block`],
    borderBottom: '1px solid',
    borderColor: 'omegaLighter'
  }
}

export const Header = ({ children }) => {
  const context = useContext(pageContextProvider)

  const { services, darkMode } = context.pageContext

  const algolia = services && services.algolia
  const categories = useBlogCategories()

  return (
    <Box full sx={styles.wrapper}>
      <Container variant='wide' sx={styles.container}>
        {/* <Box sx={{ width: '100%', display: ['none', 'block'] }}>
          <Banner slot="9401402906" height="200px" format='horizontal' />
        </Box> */}
        <Flex variant='layout.header'>
          <Box sx={styles.searchContainer}>{algolia && <Search />}</Box>
          <Box sx={styles.logoContainer}>
            <HeaderLogo />
          </Box>

          <Box sx={styles.colorModeContainer}>
            {darkMode && <HeaderColorMode />}
          </Box>
        </Flex>
        <Box sx={styles.categoriesContainer}>
          <Categories
            categories={categories}
            variant='horizontal'
            omitTitle
          />
        </Box>
      </Container>
      {children}
    </Box>
  )
}