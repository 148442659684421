import React, { Component } from 'react';
import { Box } from 'theme-ui';

class Banner extends Component {

  componentDidMount() {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <Box style={{ display: 'block', margin: 3, height: this.props.height || 'auto', textAlign: 'center' }}>
        <ins className='adsbygoogle'
          style={{ display: 'inline-block', width: '100%', height: this.props.height }}
          data-ad-client='ca-pub-5357935026189098'
          data-ad-slot={this.props.slot}
          data-ad-format={this.props.format || 'auto'}
          data-full-width-responsive="true"
        >
        </ins>
      </Box>
    );
  }
}

export default Banner;